import React from "react";
import ReactGA from "react-ga4";
import "./kids.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function Kids() {
  useDocumentTitle("Kingdom Kids");
  ReactGA.send({ hitType: "pageview", page: "/kids" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/kids-bg.jpg)", backgroundPosition: "center" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Kingdom Kids</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Children’s Ministry</h2>
          </header>
          <div className="row gy-4">
            <div className="col-xs-12">
              <blockquote className="blockquote">
                <p className="mb-2">
                  Train up a child in the way he should go, And even when he is
                  old he will not depart from it.
                </p>
                <footer className="blockquote-footer">
                  <cite title="Source Title">Proverbs 22:6e</cite>
                </footer>
              </blockquote>
              <p>
                Children are a heritage from the Lord. Ministering the spiritual
                needs and well-being of the youth and Children of the church is
                one of the most important ministries of the church. Shaping the
                leaders of tomorrow is a privilege and ought be done with utmost
                reverence. Love of Christ CSI Church is committed to serve
                the children and youth of the church and guide them towards the
                purpose that God had planned for them.
              </p>
              <p>
                Sunday classes are held in the church campus every Sunday at
                11:00 AM. Our teachers are passionate to instill the word of God
                in younger minds and help them focus on their purpose in Christ.
              </p>

              <p>We have classes for children of ages 3-15.</p>
              <ul>
                <li>
                  Ages 3-5 will be learning series of topics with engaging
                  crafts and action songs.
                </li>
                <li>
                  Ages 6-10 will be learning Bible stories guided by a great
                  curriculum (Son Seekers).
                </li>
                <li>
                  Ages 11-15 will be learning a curriculum approved by the
                  CSI diaspora diocese. This includes concepts of canonization
                  of the Bible, history of Bible, Trinity, creeds, sacraments,
                  sin, commandments, salvation etc.
                </li>
              </ul>
              <p>
                Come, learn, participate and volunteer in the Children’s
                Ministry at Love of Christ CSI Church. We would love to see
                you there. May God bless you all!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
