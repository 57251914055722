import { useState } from "react";

export default function HomeSpecialHero() {
  const [muted, setMute] = useState(true);
  return (
    <>
      <section
        className="text-center pt-lg-0 hero-home special-home-hero"
        id="hero"
      >
        <div className="embed-responsive embed-responsive-16by9 video-top-mar mt-lg-0">
          <video id="myVideo" loop autoPlay playsInline muted={muted}>
            <source
              src={`${process.env.REACT_APP_INTRO_VIDEO}`}
              type="video/mp4"
            ></source>
          </video>
          <div className="floating-mute-icon">
            {muted && (
              <i
                className="bi bi-volume-mute-fill h3 text-light"
                onClick={() => setMute(false)}
              ></i>
            )}
            {!muted && (
              <i
                className="bi bi-volume-up-fill h3 text-light"
                onClick={() => setMute(true)}
              ></i>
            )}
          </div>
        </div>
      </section>
      <section id="features">
        <div className="container-fluid p-0">
          <div className="row p-0 text-center align-items-stretch g-0">
            <div className="col-lg-3 col-md-6">
              <div className="p-5 text-white h-100 bg-primary">
                <h3 className="h4">Love</h3>
                <p className="mb-0 text-small">
                  Let us love one another, because love is from God; everyone
                  who loves is born of God and knows God.
                  <br />
                  <span className="bible-reference">1 John 4:7</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="p-5 text-white h-100 bg-primary-shade">
                <h3 className="h4">Fellowship</h3>
                <p className="mb-0 text-small">
                  For where two or three are gathered in my name, I am there
                  among them.
                  <br />
                  <span className="bible-reference">Matthew 18:20</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 order-lg-3">
              <div className="p-5 text-white h-100 bg-primary">
                <h3 className="h4">Communion</h3>
                <p className="mb-0 text-small">
                  For as often as you eat this bread and drink the cup, you
                  proclaim the Lord's death until he comes.
                  <br />
                  <span className="bible-reference">1 Corinthians 11:26</span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 order-lg-4">
              <div className="p-5 text-white h-100 bg-primary-shade">
                <h3 className="h4">Church Beliefs</h3>
                <p className="mb-0 text-small">
                  So we, who are many, are one body in Christ, and individually
                  we are members one of another.
                  <br />
                  <span className="bible-reference">Romans 12:5</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
