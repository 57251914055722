import ReactGA from "react-ga4";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Events from "./pages/events";
import About from "./pages/about";
import Pastors from "./pages/pastors";
import Gallery from "./pages/gallery";
import NoMatch from "./pages/nomatch";
import FAQs from "./pages/faq";
import Offering from "./pages/offering";
import { useEffect } from "react";
import Kids from "./pages/kids";
import Outreach from "./pages/outreach";
import Contacts from "./pages/contacts";
import News from "./pages/news";
import PDFView from "./pages/pdf";
import History from "./pages/history";
import Ministries from "./pages/ministries";

function App() {
  let location = useLocation();

  useEffect(() => {
    ReactGA.initialize([
      { trackingId: "G-4NZQYLRVF9" },
      { trackingId: "AW-16712956940" },
    ]);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    document.body.setAttribute("data-page", location.pathname);
  }, [location]);

  return (
    <>
      <Header></Header>
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="events" element={<Events />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="pastors" element={<Pastors />} />
        <Route path="faq" element={<FAQs />} />
        <Route path="donation" element={<Offering />} />
        <Route path="ministries" element={<Ministries />} />
        <Route path="kids" element={<Kids />} />
        <Route path="outreach" element={<Outreach />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="news" element={<News />} />
        <Route path="pdf" element={<PDFView />} />
        <Route path="history" element={<History />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
