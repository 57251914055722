/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const { pathname } = useLocation();

  useEffect(() => {
    /* =====================================================
		SWITCH NAVBAR VARIANT ON MOBILE & DESKTOP
	===================================================== */
    // var navbar = document.querySelector(".navbar");
    // function switchNavbar() {
    //     if (window.outerWidth > 976) {
    //       navbar.classList.add("navbar-dark");
    //       navbar.classList.remove("navbar-light");
    //     } else {
    //       navbar.classList.add("navbar-light");
    //       navbar.classList.remove("navbar-dark");
    //     }
    // }
    // window.addEventListener("load", switchNavbar);
    // window.addEventListener("resize", switchNavbar);

    /* =========================================
		HUMBERGUR MENU ACTIVATE
	========================================= */
    document
      .querySelector(".navbar-toggler")
      .addEventListener("click", function () {
        document.querySelector(".navbar-toggler").classList.toggle("active");
      });
  }, []);

  const homeHashPrefix = pathname === "/" ? "" : "/";
  const aboutHashPrefix = pathname === "/about" ? "" : "/about";

  return (
    <header className="header">
      <nav
        className="navbar navbar-expand-lg navbar-dark position-absolute w-100"
        id="navbar"
      >
        <div className="container">
          <Link
            className="navbar-brand d-block d-lg-none"
            to={`${homeHashPrefix}`}
          >
            <img
              className="round-header-image"
              src="img/csi.png"
              alt="Church of South India"
              width="46"
            />
            <img
              className="round-header-image"
              src="img/loccsi.svg"
              alt="Love of Christ CSI"
              width="46"
            />
            <span className="ms-2 text-light">Love of Christ CSI</span>
          </Link>
          <button
            className="navbar-toggler navbar-toggler-end"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav d-none d-lg-block px-2">
              <li className="nav-item m-0 text-center">
                <Link className="navbar-brand m-0" to={`${homeHashPrefix}`}>
                  <img
                    className="round-header-image ms-2"
                    src="img/loccsi.svg"
                    alt="Love of Christ CSI"
                    width="80"
                  />
                </Link>
              </li>
            </ul>
            <div className="col d-flex justify-content-start justify-content-lg-center flex-wrap">
            <ul className="navbar-nav d-none d-lg-block px-2">
              <li className="nav-item m-0 text-center">
                <Link className="navbar-brand m-0" to={`${homeHashPrefix}`}>
                  <span className="text-light church-name fs-6">
                    Love of Christ CSI Church
                  </span>
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to={`${homeHashPrefix}`}>
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Us
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to={`${aboutHashPrefix}`}>
                      Who we are
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/history">
                      History
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to={`${aboutHashPrefix}#mission`}
                    >
                      Mission
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to={`${aboutHashPrefix}#vision`}
                    >
                      Vision
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/pastors">
                      Our Pastors
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={`/contacts`}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Ministries
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/kids">
                      Kingdom Kids
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/outreach">
                      Outreach Oasis
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/events">
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/donation">
                  Donate
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gallery">
                  Gallery
                </Link>
              </li>
            </ul>
            </div>
            <ul className="navbar-nav d-none d-lg-block px-4">
              <li className="nav-item m-0 text-center">
                <Link className="navbar-brand m-0" to={`${homeHashPrefix}`}>
                  <img
                    className="round-header-image"
                    src="img/csi.png"
                    alt="Church of South India"
                    width="80"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
