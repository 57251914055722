import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import "./events.scss";
import { map } from "lodash";
import useDocumentTitle from "../../components/useDocumentTitle";
import Loader from "../../components/loader";
import { useSearchParams } from "react-router-dom";

export default function Events() {
  useDocumentTitle("Events");
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/eventPageData`;

  const calendarRef = useRef(null);
  const [searchParams] = useSearchParams();
  const initialView = searchParams.get("view");

  const [renderList, setRenderList] = useState(false);
  const [wrapperClass, setWrapperClass] = useState("");
  const [events, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const switchMobile = () => {
    if (window.outerWidth > 976) {
      setRenderList(false);
      calendarRef.current?.calendar?.changeView("dayGridMonth");
    } else {
      setRenderList(true);
      calendarRef.current?.calendar?.changeView("listMonth");
    }
  };

  useEffect(() => {
    window.addEventListener("load", switchMobile);
    window.addEventListener("resize", switchMobile);
    switchMobile();
    setLoading(true);
    fetch(dataUrl)
      .then((response) => response.json())
      .then((events) => {
        setEvents(events);
        setLoading(false);
      });
  }, [dataUrl]);

  useEffect(() => {
    if (!renderList && initialView === "list") {
      setRenderList(true);
      calendarRef.current?.calendar?.changeView("listYear");
    }
  }, [initialView, renderList]);

  const handleDateClick = (arg) => {
    // alert(arg.dateStr);
  };

  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/events-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Event Calendar</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <script type="application/ld+json">
        {JSON.stringify(
          map(events, (event) => ({
            "@context": "https://schema.org/",
            "@type": "Event",
            name: event.title,
            desciption: event.title,
            eventAttendanceMode:
              "https://schema.org/OfflineEventAttendanceMode",
            eventStatus: "https://schema.org/EventScheduled",
            location: event.location && {
              "@type": "Place",
              name: event.location || "NA",
              address: event.location || "NA",
            },
            performer: {
              "@type": "Organization",
              name: "Love of Christ CSI Church",
              url: "https://loveofchristcsichurch.org",
            },
            organizer: {
              "@type": "Organization",
              name: "Love of Christ CSI Church",
              url: "https://loveofchristcsichurch.org",
            },
            startDate: event.start,
            endDate: event.end,
          }))
        )}
      </script>
      <section className="py-5" id="sermons">
        <div className={`container py-5 ${wrapperClass}`}>
          <div className="row gy-4">
            <div className="col-xs-12">
              <p>
                Our events are your gateway to a vibrant calendar filled with
                joy, inspiration, and community spirit! Join us as we gather for
                a variety of events designed to enrich your spiritual journey,
                foster fellowship, and spread the love of Christ in our
                community.
              </p>
              <br />
              {isLoading && (
                <div className="loader-event">
                  <Loader></Loader>
                </div>
              )}
              {!isLoading && (
                <FullCalendar
                  ref={calendarRef}
                  plugins={[bootstrap5Plugin, dayGridPlugin, listPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,listMonth",
                  }}
                  buttonIcons={{
                    prev: "caret-left-fill",
                    next: "caret-right-fill",
                  }}
                  themeSystem="bootstrap5"
                  initialView={renderList ? "listMonth" : "dayGridMonth"}
                  dateClick={(e) => handleDateClick(e)}
                  datesSet={(e) => setWrapperClass(e.view.type)}
                  events={events}
                  noEventsContent="No upcoming events for the selected month, please check next month."
                />
              )}
              <br />
              <br />
              <p>
                <h5>How to Get Involved:</h5>

                <p>
                  Your presence adds to the vibrancy of our events, and we would
                  love to see you there! Keep an eye on this page for regular
                  updates, or subscribe to our newsletter to receive event
                  announcements directly in your inbox. Whether it's a worship
                  night, community service project, or a fellowship gathering,
                  there's something for everyone.
                </p>

                <h5>Stay Connected:</h5>

                <p>
                  Don't miss out on the latest happenings at Love of Christ CSI
                  Church. Follow us on social media, subscribe to our
                  newsletter, and mark your calendar for the upcoming events
                  that promise to inspire, connect, and deepen your faith.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
