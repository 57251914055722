import React from "react";
import ReactGA from "react-ga4";
import "./ministries.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function Ministries() {
  useDocumentTitle("Ministries");
  ReactGA.send({ hitType: "pageview", page: "/ministries" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/hero-bg-3.jpg)" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Ministries</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="about-us">
        <div className="container">
          <div className="row gy-4">
            <div className="col-xs-12">
              <p>
                Love of Christ CSI Church Ministries
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
